<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Entry Details" @close-sidebar="closeSidebar">
                <sidebar-details :msisdn="MSISDN" @close-sidebar="closeSidebar"></sidebar-details>
            </app-sidebar-details>
        </template>

        <div class="page-smsc-entries container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Entries</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="msisdn-search">
                        <div class="row">
                            <div class="col-4 col-tab-12">
                                <app-input
                                    v-model="filter.word"

                                    label="MSISDN"

                                    :mask="msisdn_mask"
                                />
                            </div>
                            <div class="col-2 is-hidden-tab">
                                <button class="btn btn-secondary" @click="onMSISDNSearch">Search</button>
                            </div>
                            <div class="col-2 is-hidden-tab">
                                <button class="btn btn-secondary" @click="clearMSISDNSearch">Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row smsc-entries-outer">
                <div class="col-12 col-tab-12">
                    <app-table
                        class="smsc-entries-table"
                        title=""
                        :cols="cols"
                        :rows="smscEntries"

                        :clickable="true"
                        :selected-row="MSISDN"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        @row-click="showDetails"
                        @row-click-mobile="openDetails"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
                <!-- <div class="col-4 col-tab-12">
                    <app-adding-banner 
                        header="Add Entry" 
                        description="Add a entry to the SMSC"
                        :route="{name: 'smsc-add-entry'}"
                        action-title="Add"
                        image="service"
                    />
                </div> -->
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'
import sidebarDetails from '@/views/smsc/smsc-details'

import appAddingBanner from '@/components/app-adding-banner'
import appInput from '@/components/app-input'
import appPagination from '@/components/app-pagination'
import appTable from '@/components/app-table'
import appLoader from '@/components/app-loader'

export default {
    components: {
        baseLayout,
        appInput,
        appSidebarDetails,
        sidebarDetails,
        appAddingBanner,
        appTable,
        appLoader,
        appPagination
    },

    data() {
        return {
            MSISDN: '',
            MSISDNSearch: '',
            loading: false,
            smscEntries: [],

            filter: {
                word: '',
                previous: '',

                sort: {
                    field: 'CHANGETIME',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'MSISDN', title: 'MSISDN'       },
                { field: 'CHANGETIME', title: 'Change Time' },
                { field: 'Status', title: 'Change Time' },
            ],

            cols: [
                { key: 'MSISDN',          title: 'MSISDN',         sort: { field: 'MSISDN'     }, highlight: true   },
                { key: 'ChangeTimeNanos', title: 'Last Updated',   sort: { field: 'CHANGETIME' }, type: 'datenanos' },
                { key: 'Status',          title: 'Ported In',                                     type: 'yesno'     },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    methods: {
        init(to, from) {
            this.currentPage = to.params.page
        },

        /**
         * Service Details
         */
        openDetails(row) {
            this.$router.push({ name: 'smsc-entries-details', params: { msisdn: row.MSISDN } })
        },

        showDetails(row) {
            if (this.MSISDN == row.MSISDN) {
                this.closeSidebar()
            } else {
                this.MSISDN = row.MSISDN
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.MSISDN = null
            this.$emit('close-sidebar')
        },

        onMSISDNSearch() {
            this.getSMSCEntries(1)
        },

        clearMSISDNSearch() {
            this.filter.word = ''

            this.getSMSCEntries(1)
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getSMSCEntries()
        },

        onPageChange(page) {
            this.getSMSCEntries(page)
        },

        getSMSCEntries(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            console.warn('filter', {...filter})

            let params = {
                "SPID": this.current_spid,
                // 'MtxType': 'CustSubscriberExtension',
                // 'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }
            console.warn("SPID", params.SPID)

            const word = filter.word.trim()
            console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            this.$store.dispatch('api_smsc/GetSMSCEntriesPaginated', params).then(response => {
                console.log('resp', response)
                this.smscEntries = response.SMSCEntries

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo
                console.log('DisplayPageList',DisplayPageList,'PageNumber', PageNumber, 'TotalItemCount', TotalItemCount)

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.smscEntries = []

                this.loading = false
                if (this.filter.word) {

                }
            })
        },


        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getSMSCEntries(this.pagination.page, { word: search.word })
            }
        },
    },

    created () {
        this.getSMSCEntries(this.currentPage)
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',

            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ]),

        msisdn_mask() {
            return {
                regex: String.raw`^\d*$`
            }
        },
    },
    
    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-smsc-entries {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .msisdn-search {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);

        & > .row {
            margin: 0 #{ - $container-padding / 2 };
        }
    }

    .smsc-entries-table {
        @include table-cols-width((250px, 120px, 200px, 120px, 85px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }

    .smsc-entries-outer {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-smsc-entries {
        .smsc-entries-table {
            @include table-cols-width((150px, 100px, 150px, 100px, 85px), true);
        }
    }

    .is-hidden-tab {
        display: none;
    }
    
    .smsc-entries-outer {
        margin-top: 15px;
    }
}

@media (max-width: $mobile-size) {
    .page-smsc-entries {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .smsc-entries-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>